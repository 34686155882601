<template>
  <div>
    <transition name="view-fade">
      <div v-if="!isEdit" class="view">
        <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-4x">
          {{ getTitle(form) }}
        </h2>
        <div class="columns columns-table is-gapless is-mobile m-b-3x">
          <div class="column">
            <div class="th">
              {{ $t('Details') }}
            </div>
          </div>
          <div class="column has-text-right">
            <div class="th">
              <a class="has-text-white" @click.prevent="handleEdit">
                <EditFilled />
                {{ $t('Edit') }}</a>
            </div>
          </div>
        </div>
        <div class="columns columns-table is-gapless m-b-3x">
          <div class="column">
            <div>
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Description') }}
              </div>
              <div>{{ getDescription(form) }}</div>
            </div>
          </div>
        </div>
        <div class="columns columns-table is-gapless is-multiline m-b-3x">
          <div class="column is-6 mt-2">
            <div>
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Property') }}
              </div>
              <div>{{ form.property.name }}</div>
            </div>
          </div>
          <div class="column is-6 mt-2">
            <div>
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Room Name') }}
              </div>
              <div>{{ form.room ? form.room.name : '' }}</div>
            </div>
          </div>
          <template v-if="showDate(form)">
            <div class="column is-6 mt-2">
              <div>
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Checkin Date') }}
                </div>
                <div>{{ formatDate(value.data && value.data.checkin) }}</div>
              </div>
            </div>
            <div class="column is-6 mt-2">
              <div v-if="form.type === TYPES.CANCEL_RESERVATION">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Housekeeper') }}
                </div>
                <div>{{ (value.data && value.data.housekeeper) || '-' }}</div>
              </div>
              <div v-else>
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Checkout Date') }}
                </div>
                <div>{{ formatDate(value.data && value.data.checkout) }}</div>
              </div>
            </div>
          </template>
        </div>

        <div v-if="form.images.length" class="columns columns-table is-gapless m-b-3x">
          <div class="column">
            <div>
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('Upload pictures') }}
              </div>
              <div class="images">
                <div v-for="(img, index) in value.images" :key="index" class="img">
                  <img :src="$filters.imageUrl(img.path)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="form m-b-4x">
        <div class="columns columns-table is-gapless m-b-3x">
          <div class="column">
            <div class="th">
              {{ $t('Details') }}
            </div>
          </div>
        </div>
        <DetailForm :value="form" @cancel="isEdit = false" @submit="updateAlert" />
      </div>
    </transition>
    <Comments :value="comments" :alert-id="value.id" @change="loadComments" />
  </div>
</template>

<i18n src="../locales.json"></i18n>

<i18n>
{
  "en": {
    "Edit": "Edit",
    "Details": "Details",
    "Description": "Description",
    "Property": "Property",
    "Room Name": "Room Name",
    "Upload pictures": "Uploaded pictures"
  },
  "ja": {
    "Edit": "編集する",
    "Details": "詳細",
    "Description": "詳細",
    "Property": "物件",
    "Room Name": "部屋番号",
    "Upload pictures": "写真アップロード"
  }
}
</i18n>

<script>
import { EditFilled } from '@ant-design/icons-vue';
import Comments from '@/views/alerts/components/Comments';
import DetailForm from '@/views/alerts/components/DetailForm';
import { getResponseError, appendImageUrl } from '@/utils/util';
import getters from '@/views/alerts/mixins/getters';
import { TYPES } from '@/config/alert';

export default {
  name: 'Detail',
  components: {
    DetailForm,
    Comments,
    EditFilled,
  },
  mixins: [getters],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['alert-update', 'alert-read'],
  data() {
    return {
      TYPES,
      isEdit: false,
      form: {},
      currentAlert: {},
      comments: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.setCurrentAlert(nv);
          this.loadComments();
          this.readAlert();
        }
      },
    },
  },
  methods: {
    setCurrentAlert(attributes) {
      const alert = {
        ...attributes,
        images: appendImageUrl(attributes.images),
      };

      this.form = JSON.parse(JSON.stringify(alert));
      this.currentAlert = JSON.parse(JSON.stringify(alert));
    },
    loadComments() {
      this.$store.dispatch('alerts/comments', this.value.id).then((response) => {
        this.comments = response ? response.data : [];
      });
    },
    readAlert() {
      if (!this.value.readAt) {
        this.$store.dispatch('alerts/read', this.value.id);
        this.$emit('alert-read', this.value);
      }
    },
    handleEdit() {
      this.isEdit = true;
      this.form = JSON.parse(JSON.stringify(this.currentAlert));
    },
    async updateAlert(form) {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        const {
          images, removedImages, id, ...payload
        } = form;

        await this.$store.dispatch('alerts/update', { id, ...payload });

        if (images.length > 0) {
          await this.$store.dispatch('alerts/addImages', { id, files: images });
        }

        if (removedImages.length > 0) {
          await Promise.all(
            removedImages.map(async (img) => {
              if (img.id) {
                await this.$store.dispatch('alerts/deleteImage', { id, imageId: img.id });
              }
            }),
          );
        }

        this.isEdit = false;
        this.$message.success('Update success');
        this.$emit('alert-update', this.value.id);
      } catch (e) {
        console.log(e);
        this.$message.error(getResponseError(e));
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-top: 10px;
  object-fit: cover;
  overflow: hidden;
}
</style>
