<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Alerts') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div class="column has-text-right-mobile is-narrow">
            <a-button
              v-permission="['add-alert']"
              :size="size"
              style="min-width: 180px"
              type="primary"
              @click="handleAdd"
            >
              {{ $t('Add Alert') }}
            </a-button>
          </div>
        </div>
        <component
          :is="componentList"
          :value="list"
          :is-fetching="isFetching"
          :pagination="pagination"
          @table-change="handleTableChange"
          @show="showDetail"
          @delete="fetchData"
        />
      </div>
    </div>
    <Modal ref="detailModal" closable>
      <template #default="{ hide }">
        <Detail
          v-if="selectRecord"
          :value="selectRecord"
          @cancel="hide"
          @alert-update="handleAlertUpdate"
          @alert-read="handleAlertRead"
        />
      </template>
    </Modal>
  </div>
</template>

<i18n>
{
  "en": {
    "Alerts": "Alerts",
    "Add Alert": "Add Alert"
  },
  "ja": {
    "Alerts": "通知",
    "Add Alert": "通知を追加"
  }
}
</i18n>

<script>
import { defineAsyncComponent } from 'vue';
import SearchForm from '@/views/alerts/components/SearchForm';
import Detail from '@/views/alerts/components/Detail';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';

export default {
  name: 'Alerts',
  components: {
    Modal,
    Detail,
    AlertsList: defineAsyncComponent(() => import(/* webpackChunkName: "desktop" */ '@/views/alerts/components/AlertsList')),
    AlertsListMobi: defineAsyncComponent(() => import(/* webpackChunkName: "mobile" */ '@/views/alerts/components/AlertsListMobi')),
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      size: 'large',
      list: undefined,
      isFetching: false,
      selectRecord: undefined,
    };
  },
  computed: {
    componentList() {
      return this.$mq === 'mobile' ? 'AlertsListMobi' : 'AlertsList';
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('alerts/list', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.list = data.map((p, index) => {
            p._id = index + 1;

            return p;
          });

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleAdd() {
      this.$router.push({ name: 'alerts-add' });
    },
    showDetail(record) {
      this.selectRecord = record;
      this.$refs.detailModal.show();
    },
    handleAlertRead(record) {
      record.readAt = new Date();
    },
    handleAlertUpdate() {
      this.$refs.detailModal.hide();
      this.fetchData();
    },
  },
};
</script>
