<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <a-textarea
        v-model:value="form.body"
        :placeholder="$t('add comment')"
        :size="size"
        type="text"
        :auto-size="autoSize"
        @focus="isFocus = true"
        @blur="isFocus = false"
      />
    </div>
    <div v-show="showButton" class="column is-12">
      <a-upload
        name="file"
        accept="image/png, image/jpeg"
        class="avatar-uploader"
        list-type="picture-card"
        :file-list="fileList"
        :action="addImagesAction"
        :multiple="true"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <div class="has-text-center">
          <IconImage />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
      </a-upload>
    </div>
    <div v-show="showButton" class="column has-text-right is-12">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-2x"
        style="min-width: 155px"
        @click="handleCancel"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"add comment": "add comment...",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"add comment": "コメントを追加...",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import IconImage from '@/components/icons/IconImage';
import { appendImageUrl } from '@/utils/util';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'CommentForm',
  components: { IconImage },
  mixins: [FileUploader],
  props: {
    isSubmitting: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      fileList: [],
      removedImages: [],
      form: {
        alertId: undefined,
        id: undefined,
        body: undefined,
        images: [],
      },
      rules: {
        // todo
      },
      isFocus: false,
      size: 'large',
    };
  },
  computed: {
    addImagesAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/alerts/${this.value.alertId}/comments/${this.value.id}/images`;
    },
    autoSize() {
      return this.form.body || this.isFocus ? { minRows: 3 } : { minRows: 1 };
    },
    showButton() {
      return !!this.form.body || this.isFocus;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.form.id = nv.id;
          this.form.alertId = nv.alertId;
          this.form.body = nv.body;

          if (nv.images && nv.images.length) {
            const images = appendImageUrl(nv.images);

            this.fileList = images;
            this.form.images = images;
          }
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form, removedImages: this.removedImages });
        this.resetFields();
      } catch (e) {
        console.log(e);
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.removeFromImages(file);
      this.removedImages.push(file);

      // prevent default removal
      return false;
    },
    resetFields() {
      this.form.id = this.value ? this.value.id : undefined;
      this.form.body = this.value ? this.value.body : undefined;
      this.form.images = this.value && this.value.images ? this.value.images : [];
      this.fileList = [];
    },
    handleCancel() {
      this.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader > .ant-upload.ant-upload-select-picture-card {
  width: auto !important;
}

::v-deep .avatar-uploader > .ant-upload-select > .ant-upload {
  float: left;
  width: 104px !important;
  height: 104px !important;
  margin: 0 !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #00596c;
  color: #00596c !important;
  user-select: none;
  line-height: 1.5 !important;
  @include mobile {
    width: 50px !important;
    height: 50px !important;
  }
}

::v-deep .avatar-uploader.hasImage > .ant-upload {
  border: none;
}

::v-deep .avatar-uploader .ant-upload-select > i {
  font-size: 32px;
  color: #999;
  @include mobile {
    font-size: 12px;
  }
}

::v-deep .avatar-uploader .ant-upload-text {
  margin-top: 2px;
  color: #666;
  @include mobile {
    margin-top: -5px;
    font-size: 12px;
  }
}
</style>
