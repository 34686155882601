<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom
        v-model:value="form.roomId"
        :property-id="form.propertyId"
        :disabled="!form.propertyId"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Alert Name')" name="title">
      <a-input v-model:value="form.title" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Detail')" name="description">
      <a-textarea
        v-model:value="form.description"
        :size="size"
        type="text"
        :auto-size="{ minRows: 3 }"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Upload pictures')" name="images">
      <a-upload
        name="file"
        accept="image/png, image/jpeg"
        list-type="picture-card"
        :file-list="fileList"
        :action="addImagesAction"
        :multiple="true"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <a-button type="primary" size="large">
          {{ $t('Browse File') }}
        </a-button>
      </a-upload>
    </a-form-item>

    <div class="column form-title is-12 m-b-2x px-4">
      {{ $t('Send alert to') }}
    </div>
    <a-form-item
      v-if="isInternalUser"
      class="column is-12"
      :label="$t('Housekeeping Company')"
      name="partnerId"
    >
      <InputHousekeeping v-model:value="form.partnerId" :property-id="form.propertyId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Housekeeper Name')" name="userId">
      <InputHousekeeper v-model:value="form.userId" :partner-id="form.partnerId" />
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Property": "Property",
    "Room Name": "Room Name",
    "Alert Name": "Alert Name",
    "Detail": "Detail",
    "Upload pictures": "Upload pictures",
    "Browse File": "Browse File",
    "Send alert to": "Send alert to",
    "Housekeeping Company": "Housekeeping Company",
    "Housekeeper Name": "Housekeeper Name",
    "Save": "Save",
  },
  "ja": {
    "Property": "物件",
    "Room Name": "部屋番号",
    "Alert Name": "通知名",
    "Detail": "詳細",
    "Upload pictures": "写真アップロード",
    "Browse File": "閲覧ファイル",
    "Send alert to": "通知を誰に送りますか？",
    "Housekeeping Company": "清掃会社",
    "Housekeeper Name": "清掃担当者",
    "Save": "保存",
  }
}
</i18n>

<script>
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import InputHousekeeping from '@/components/InputHousekeeping';
import InputHousekeeper from '@/components/InputHousekeeper';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'EditForm',
  components: {
    InputHousekeeper,
    InputHousekeeping,
    InputRoom,
    InputProperty,
  },
  mixins: [FileUploader],
  props: {
    isSubmitting: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel', 'image-removed'],
  data() {
    return {
      fileList: [],
      form: {
        id: undefined,
        title: undefined,
        description: undefined,
        images: [],
        propertyId: undefined,
        roomId: undefined,
        housekeeperId: undefined,
        housekeepingId: undefined,
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Title is required',
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
    };
  },
  computed: {
    addImagesAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/alerts/${this.value.id}/images`;
    },
    isInternalUser() {
      const disallowed = ['HC', 'PO'];
      const { roles } = this.$store.getters;
      return !roles.some((r) => disallowed.includes(r));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (nv.images && nv.images.length) {
            this.fileList = nv.images;
          }
          const keys = Object.keys(nv);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
  },
  created() {
    if (!this.isInternalUser) {
      this.form.partnerId = this.$store.getters.userPartner.id;
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.$emit('submit', { ...this.form });
      } catch (e) {
        console.log(e);
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.removeFromImages(file);
      this.$emit('image-removed', file);
      // prevent default removal
      return false;
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-list {
  width: 100px;
  height: 100px;

  .overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &:hover .overlay {
    opacity: 1;
  }
}
</style>
