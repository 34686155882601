<template>
  <div class="comment">
    <transition name="view-fade">
      <a-spin v-if="!isEdit" :spinning="isLoading">
        <div class="columns is-mobile view">
          <div class="column">
            <div>
              <span class="has-text-primary has-text-weight-bold m-r">
                {{ author }}
              </span>
              <span class="has-text-grey">
                {{ $filters.date(value.createdAt) }}
              </span>
            </div>
            <p>{{ value.body }}</p>
            <div v-for="(img, index) in value.images" :key="index" class="img">
              <img :src="$filters.imageUrl(img.path)" width="80" />
            </div>
          </div>
          <div class="column is-narrow">
            <div class="action">
              <EditFilled class="has-text-primary m-r-3x" @click="isEdit = true" />
              <DeleteFilled class="has-text-danger" @click="handleDel" />
            </div>
          </div>
        </div>
      </a-spin>

      <div v-else class="form">
        <CommentForm :value="value" @cancel="isEdit = false" @submit="saveComment" />
      </div>
    </transition>
  </div>
</template>

<script>
import { EditFilled, DeleteFilled } from '@ant-design/icons-vue';
import CommentForm from '@/views/alerts/components/CommentlForm';
import { getFullname } from '@/utils/users';

export default {
  name: 'Comment',
  components: {
    CommentForm,
    EditFilled,
    DeleteFilled,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      isEdit: false,
      isLoading: false,
    };
  },
  computed: {
    author() {
      const { author } = this.value;

      if (!author) {
        return 'Unknown User';
      }

      return getFullname({
        fname: author.firstName,
        lname: author.lastName,
      });
    },
  },
  methods: {
    async handleDel() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('alerts/deleteComment', this.value);
        this.$emit('change');
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
    async saveComment(comment) {
      try {
        this.isLoading = true;

        const {
          images, removedImages, id, ...payload
        } = comment;
        const baseImgPayload = { id, alertId: this.value.alertId };

        await this.$store.dispatch('alerts/updateComment', { id, ...payload });

        if (images.length > 0) {
          const imgPayload = { ...baseImgPayload, files: images };
          await this.$store.dispatch('alerts/addCommentImage', imgPayload);
        }

        if (removedImages.length > 0) {
          await Promise.all(
            removedImages.map(async (img) => {
              if (img.id) {
                await this.$store.dispatch('alerts/deleteCommentImage', {
                  ...baseImgPayload,
                  imageId: img.id,
                });
              }
            }),
          );
        }

        this.$emit('change');
      } catch (e) {
        console.log(e);
      } finally {
        this.isEdit = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 5px;
  margin-top: 10px;
  object-fit: cover;
  overflow: hidden;
}
</style>
