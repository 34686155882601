<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <a-form-item class="column is-12" :label="$t('Alert Name')" name="title">
      <a-input v-model:value="form.title" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Detail')" name="description">
      <a-textarea
        v-model:value="form.description"
        :size="size"
        type="text"
        :auto-size="{ minRows: 3 }"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Room Name')" name="roomId">
      <InputRoom v-model:value="form.roomId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Upload pictures')" name="images">
      <a-upload
        name="file"
        accept="image/png, image/jpeg"
        list-type="picture-card"
        :file-list="fileList"
        :action="addImagesAction"
        :multiple="true"
        :before-upload="handleBeforeUpload"
        :remove="handleRemove"
        @change="handleImageChange"
      >
        <a-button type="primary" size="large">
          {{ $t('Upload More') }}
        </a-button>
      </a-upload>
    </a-form-item>
    <div class="column has-text-right is-12">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-2x"
        style="min-width: 155px"
        @click="handleCancel"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Detail": "Detail",
"Property": "Property",
"Room Name": "Room Name",
"Upload pictures": "Upload pictures",
"Upload More": "Upload More",
"Cancel": "Cancel",
"Save": "Save",
"Not collected": "Not collected",
"collected": "collected",
"Alert Name": "Alert Name"
},
"ja": {
"Detail": "詳細",
"Property": "物件",
"Room Name": "部屋番号",
"Upload pictures": "写真アップロード",
"Upload More": "アップロード",
"Cancel": "取り消し",
"Save": "保存",
"Not collected": "収集されない",
"collected": "収集",
"Alert Name": "通知名"
}
}
</i18n>

<script>
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import FileUploader from '@/components/mixins/FileUploader';

export default {
  name: 'DetailForm',
  components: {
    InputRoom,
    InputProperty,
  },
  mixins: [FileUploader],
  props: {
    isSubmitting: {
      type: Boolean,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['submit', 'close', 'cancel', 'image-removed'],
  data() {
    return {
      fileList: [],
      removedImages: [],
      form: {
        id: undefined,
        title: undefined,
        description: undefined,
        images: [],
        propertyId: undefined,
        roomId: undefined,
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Title is required',
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
    };
  },
  computed: {
    addImagesAction() {
      if (!this.value) {
        return '';
      }

      return `${process.env.VUE_APP_API_URL}/alerts/${this.value.id}/images`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (nv.images && nv.images.length) {
            this.fileList = nv.images;
          }
          const keys = Object.keys(nv);
          keys.forEach((k) => {
            this.form[k] = nv[k];
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        const data = {
          ...this.form,
          removedImages: this.removedImages,
        };

        await this.$refs.form.validate();
        this.$emit('submit', data);
      } catch (e) {
        console.log(e);
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);
      this.fileList = newFileList;

      this.removeFromImages(file);
      this.removedImages.push(file);
      // prevent default removal
      return false;
    },
    resetFields() {
      this.$refs.form.resetFields();
    },
    handleCancel() {
      this.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
