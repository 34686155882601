<template>
  <div>
    <div class="columns columns-table is-gapless m-b-3x">
      <div class="column">
        <div class="th">
          {{ $t('Comments') }}
        </div>
      </div>
    </div>
    <div v-if="value.length" class="comments">
      <Comment
        v-for="(comment, index) in value"
        :key="index"
        :value="comment"
        @change="$emit('change')"
      />
    </div>
    <div class="add-comment">
      <a-spin :spinning="isLoading">
        <CommentForm @submit="addComment" />
      </a-spin>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Comments": "Comments"
},
"ja": {
"Comments": "コメント"
}
}
</i18n>

<script>
import Comment from '@/views/alerts/components/Comment';
import CommentForm from '@/views/alerts/components/CommentlForm';

export default {
  name: 'Comments',
  components: { CommentForm, Comment },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    alertId: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['change'],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async addComment(comment) {
      try {
        this.isLoading = true;
        const { images, removedImages: _, ...payload } = comment;
        const res = await this.$store.dispatch('alerts/addComment', {
          ...payload,
          id: this.alertId,
        });

        if (images && images.length > 0) {
          const imagePayload = {
            id: res.data.id,
            alertId: this.alertId,
            files: images,
          };

          await this.$store.dispatch('alerts/addCommentImage', imagePayload);
        }

        this.$emit('change');
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
