<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add Alert') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm ref="form" :value="form" :is-submitting="isSubmitting" @submit="handleSubmit" />
        <a-alert v-if="alert" type="success">
          <template #message>
            <sapn v-html="$t('alert-added', { name: alert.name })" />
            <router-link to="/alerts">
              {{ $t('View alert list') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add Alert": "Add Alert",
"alert-added": "alert <strong>{name}</strong> added, ",
"View alert list": "View alert list"
},
"ja": {
"Add Alert": "通知を追加",
"alert-added": "通知 {name} が追加されました。",
"View alert list": "通知を閲覧"
}
}
</i18n>

<script>
import EditForm from '@/views/alerts/components/EditForm';
import { getResponseError } from '@/utils/util';

export default {
  name: 'Add',
  components: { EditForm },
  data() {
    return {
      alert: undefined,
      isSubmitting: false,
      error: '',
      form: {
        title: undefined,
        description: undefined,
        propertyId: undefined,
        roomId: undefined,
      },
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'alert-add') {
        this.initData();
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.$route.query.propertyId) {
        this.form.propertyId = this.$route.query.propertyId;
      }
      if (this.$route.query.roomId) {
        this.form.roomId = this.$route.query.roomId;
      }
    },
    async handleSubmit(data) {
      try {
        this.isSubmitting = true;
        this.alert = undefined;
        this.error = '';

        const { images, userId, ...payload } = data;
        const participants = [userId, this.$store.getters.uid];
        const result = await this.save(payload, participants);

        if (images.length) {
          const newImages = images.filter((img) => !img.id);

          if (newImages.length) {
            await this.$store.dispatch('alerts/addImages', {
              id: result.data.id || this.form.id,
              files: newImages,
            });
          }
        }

        this.isSubmitting = false;
        this.alert = data;
        this.$refs.form.resetFields();
      } catch (err) {
        this.isSubmitting = false;
        this.error = getResponseError(err);
      }
    },
    async save(payload, participants) {
      if (this.form.id) {
        return this.$store.dispatch('alerts/update', { ...payload, participants });
      }

      const addPayload = {
        data: { ...payload, participants },
      };

      return this.$store.dispatch('alerts/add', addPayload);
    },
  },
};
</script>
