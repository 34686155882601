<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <InputProperty v-model:value="propertyId" class="tablet-min-200" @change="debounceQuery" />
    </div>
    <div class="column is-narrow">
      <InputRoom
        v-model:value="roomId"
        :property-id="propertyId"
        class="tablet-min-200"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="debounceQuery"
      >
        <a-input :value="rangeString" :placeholder="$t('Date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column has-text-right-mobile">
      <a-button
        v-show="propertyId || roomId || rangeString"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Date range": "Date range",
"Clear": "Clear"
},
"ja": {
"Date range": "日付範囲",
"Clear": "クリア"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputRoom from '@/components/InputRoom';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchForm',
  components: {
    InputProperty,
    InputRoom,
    CalendarFilled,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      roomId: undefined,
      range: [],
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: dateFormat })},${this.$filters.date(
          this.range[1],
          { format: dateFormat },
        )}`
        : undefined;
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
    this.range = this.$route.query.range ? this.$route.query.range.split(',') : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        roomId: this.roomId,
        range: this.formattedRange,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.roomId = undefined;
      this.range = [];
      this.debounceQuery();
    },
  },
};
</script>

<style lang="scss" scoped></style>
